import React from "react";
import roadmap1 from "../assets/images/resources/roadmap-1.png";
import roadmap2 from "../assets/images/resources/roadmap-2.png";
import roadmap3 from "../assets/images/resources/roadmap-3.png";
import roadmap4 from "../assets/images/resources/roadmap-4.png";

const Roadmap = () => {
  return (
    <section className="roadmap" id="roadmap">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            <span>ROADMAP</span> 
            <br />
          </h2>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 wow fadeInUp" data-wow-duration="1500ms">
            <h4 className="text-center" style={{display:"block"}}>
              When the mint starts, the graveyard stake will be unlocked.<br />Leaderboard is ready and ranking will start.
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms">
            <div className="roadmap__single text-center">
              <div className="roadmap__inner">
                <ul className="roadmap-list">
                  <li>
                    <img src={roadmap1} alt="" />
                    <span className="roadmap-item-title-1">25%</span>
                    <span className="roadmap-item-title-2">Game, Community Giveaway</span>
                    <br />
                    <span className="roadmap-item-info">Graveyard leveling feature will be enabled!</span>
                    <br />
                    <span className="roadmap-item-info">Giving away 2 AVAX for 10 people among those who fulfill the mission in the committee!</span>
                  </li>
                  <li>
                    <img src={roadmap2} alt="" />
                    <span className="roadmap-item-title-1">50%</span>
                    <span className="roadmap-item-title-2">Game, Community Giveaway</span>
                    <br />
                    <span className="roadmap-item-info">Team change feature will be enabled!</span>
                    <br />
                    <span className="roadmap-item-info">Giving away 2 AVAX for 20 of our minters!</span>
                  </li>
                  <li>
                    <img src={roadmap3} alt="" />
                    <span className="roadmap-item-title-1">75%</span>
                    <span className="roadmap-item-title-2">Ranking Giveaway</span>
                    <br />
                    <span className="roadmap-item-info">Giving away 10 AVAX for the first 5 people in the top 100 on the leaderboard!</span>
                    <br />
                    <span className="roadmap-item-info">These ranking rewards will be distributed weekly!</span>
                  </li>
                  <li>
                    <img src={roadmap4} alt="" />
                    <span className="roadmap-item-title-1">100%</span>
                    <span className="roadmap-item-title-2">NFT Marketplace, Team Brawler Game</span>
                    <br />
                    <span className="roadmap-item-info">Opening to the secondary markets!</span>
                    <br />
                    <span className="roadmap-item-info">Early access to our Team Brawler game!</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
