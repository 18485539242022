import React from "react";
import AccordionItem from "./AccordionItem";
const Faq = () => {
  return (
    <section className="faq-one" id="faq">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            <span>FAQ</span> <br />
          </h2>
        </div>
        <div className="accrodion-grp faq-accrodion">
          <AccordionItem
              title="What are the contract addresses?"
              content={
              <div>
                <span>AvaxGhost&nbsp;<a href="https://snowtrace.io/address/0xa64856F831D4793E781A4912A2389052348f9459" target="_blank" rel="noreferrer">0xa64856F831D4793E781A4912A2389052348f9459</a></span>
                <br />
                <span>GraveDigger&nbsp;<a href="https://snowtrace.io/address/0xd3FC51e72B1c34b257a60642b37F01008B127250" target="_blank" rel="noreferrer">0xd3FC51e72B1c34b257a60642b37F01008B127250</a></span>
                <br />
                <span>Graveyard&nbsp;<a href="https://snowtrace.io/address/0x52635E65e40E2107c9327BA989fbC0EECa0531b0" target="_blank" rel="noreferrer">0x52635E65e40E2107c9327BA989fbC0EECa0531b0</a></span>
                <br />
                <span>Spirit&nbsp;<a href="https://snowtrace.io/address/0xF15609C283403123271Cb266FC2adA15A1603879" target="_blank" rel="noreferrer">0xF15609C283403123271Cb266FC2adA15A1603879</a></span>
              </div>
              }
              status={false}
            />
          <AccordionItem
            title="Is there an article about AVAXGHOST?"
            content={
              <div>
                <a href="https://medium.com/@AvaxGhostGame/boo-the-clock-is-ticking-development-update-c98c1886404a" target="_blank" rel="noreferrer">Check out our Medium article!</a>
              </div>
            }
            status={false}
          />
          <AccordionItem
            title="How many NFTs will be sold?"
            content="10.000 will be minted in total."
            status={false}
          />
          <AccordionItem
            title="How can you ensure distribution is random?"
            content="We will be using a smart contract deployed on the Avalanche C-Chain to distribute the AVAXGHOST. The tokens can be verified on the blockchain and our smart contract code can be audited by the AVAX core devs."
            status={false}
          />
          <AccordionItem
            title="Is each AVAXGHOST unique?"
            content="Yes each AVAXGHOST will be unique, we have ensured via code on the Blockchain that no two NFTs will be replicated."
            status={false}
          />
          <AccordionItem
            title="Which wallet should I use?"
            content="Metamask is the recommended wallet."
            status={false}
          />
        </div>
      </div>
    </section>
  );
};
export default Faq;
