import React,{Component} from "react";
import ReactSlider from "react-slider";
import { isConnected, connect } from '../assets/scripts/wallet.js';
import { isInt } from '../assets/scripts/globals.js';
import * as contract from '../assets/scripts/contract.js';

export default class BannerGame extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isWalletConnected: undefined,
      isContractInitialized: undefined,
      isMintStatsFetched: undefined,
      isMintEnabled: undefined,
      isSoldOut: false,
      isPageReadyToDisplay: false,
      mintAmount: 8,
      itemFee: 1,
      time: undefined,
      seconds: 0,
      countDownTimerId: undefined,
      mintStatsTimerId: undefined,
      mintStatsPollInterval: 4
    }
    this.launchDate = "December 24, 2021 20:00:00 GMT"; // December 24, 2021 20:00:00 GMT
    this.startTimer = this.startCountDownTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  handleButtonClick = (e) => {
    e.preventDefault();
    if(isConnected()) {
      if(this.state.isSoldOut) {
        // log("soldout :(");
      } else {
        if(this.state.isMintEnabled) {
          contract.mint(this.state.mintAmount);
        } else {
          // log("coming soon!");
        }
      }
    } else {
      connect();
    }
  };

  componentDidMount() {

    this.setState({isWalletConnected: isConnected()});

    document.addEventListener('onAccountUpdate', () => {

      const connected = isConnected();

      this.setState({isWalletConnected: connected});

      if(!connected) {
        this.setState({isMintStatsFetched: false});
        this.reloadData();
      } else {
        this.queryMintStats();
      }

    });

    document.addEventListener('onContractInitialized', () => {
      this.setState({isContractInitialized: true});
      this.queryMintStats();
      this.reloadData();
    });

    document.addEventListener('onMintStatsFetched', () => {
      this.setState({isMintStatsFetched: true});
      this.reloadData();
    });

    this.reloadData();
    this.queryMintStats();

    this.startCountDownTimer();
    this.startMintStatsTimer();

  }

  componentWillUnmount() {
    // use intervalId from the state to clear the intervals
    clearInterval(this.state.countDownTimerId);
    clearInterval(this.state.mintStatsTimerId);
  }

  queryMintStats = () => {

    if(this.state.isMintStatsFetched === true) {
      return;
    }

    contract.queryMintStats();

  };

  reloadData = async () => {
    
    var itemFee = 1;

    if(contract.itemFee) {
      itemFee = isInt(contract.itemFee) ? parseInt(contract.itemFee) : parseFloat(contract.itemFee);
    }

    let isSoldOut = false;
    
    if(contract.currentItemCount && contract.maxItemCount) {
      isSoldOut = parseInt(contract.currentItemCount) === parseInt(contract.maxItemCount)
    }

    this.setState({
      itemFee: itemFee,
      isMintEnabled: !contract.mintPaused,
      isSoldOut: isSoldOut
    });

    this.recalculatePageReady();

  };

  recalculatePageReady = () => {

    const pageReady = this.state.isWalletConnected !== undefined;

    this.setState({ isPageReadyToDisplay: pageReady });

  };

  changeMintAmount = (value, index) => {
    this.setState({mintAmount: value});
    // log("Value is " + value + " and index is " + index);
  };

  startCountDownTimer() {
    if (this.state.countDownTimerId === undefined && this.launchDate) {
      const date = new Date(this.launchDate);
      const now = Date.now();
      const diff = date.getTime() - now;
      const seconds = Math.floor(diff / 1000);
      if(diff > 0 && seconds > 0) {
        const intervalId = setInterval(this.countDown, 1000);
        this.setState({
          seconds: seconds,
          countDownTimerId: intervalId
        });
      }
    }
  };

  secondsToTime(secs) {
    let days = Math.floor(secs / 60 / (60 * 24));
    let hours = Math.floor((secs / (60 * 60)) % 24);
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let obj = {
      "d": days,
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  };

  startMintStatsTimer = () => {

    if(this.state.mintStatsTimerId) {
      clearInterval(this.state.mintStatsTimerId); 
    }

    const intervalId = setInterval(() => this.pollMintStats(), this.state.mintStatsPollInterval * 1000);
    this.setState({ mintStatsTimerId: intervalId })

  };

  pollMintStats = async () => {

    if(!isConnected()) {
      return;
    }

    await contract.queryMintStats();

  };

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    let time = this.secondsToTime(seconds);
    if(seconds > 0) {
      this.setState({
        time: time,
        seconds: seconds,
      });
    } else {
      clearInterval(this.state.intervalId);
      this.setState({
        time: undefined,
        seconds: 0
      });
      window.location.reload();
    }
  };

  render() {
    return (
      <section className="banner-one" id="mint">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-12"></div>
            <div className="col-xl-6 col-lg-12 text-center">
              <div className="banner-one__moc">
                <div className="ghost-1"></div>
                <div className="ghost-2"></div>
                <div className="ghost-3"></div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-12"></div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="col-xl-3 col-lg-6"></div>
              <div className="banner-one__content">
                <h1 className="banner-one__title">
                  AVAXGHOST
                </h1>
                <p className="banner-one__slogan">
                  Decentralized, Play2Earn Game
                </p>
                {this.state.isPageReadyToDisplay
                ?
                (
                  <div>
                    {this.state.time &&
                      <div className='time-to'><span>{this.state.time.d}d {this.state.time.h}h {this.state.time.m}m {this.state.time.s}s</span></div>
                    }
                    {this.state.isWalletConnected && 
                      <div>
                        <ReactSlider min={1} max={20} defaultValue={this.state.mintAmount} className="mint-slider" thumbClassName="mint-thumb" trackClassName="mint-track" onChange={(value, index) => this.changeMintAmount(value, index)} />
                        {this.state.isMintEnabled
                          ? <div>
                              <div><p className="banner-one__text">{"Mint Price is " + this.state.itemFee + " AVAX"}</p></div>
                              <div><p className="banner-one__text-2">* Maximum 20 AVAXGHOSTS per transaction</p></div>
                            </div>
                          : <div><br /></div>
                        }
                      </div>
                    }
                    <a href="#" className="banner-one__btn thm-btn mint-btn" onClick={this.handleButtonClick}>
                      {this.state.isWalletConnected
                        ? <span>{this.state.isSoldOut ? "SOLDOUT" : this.state.isMintEnabled ? ("MINT " + this.state.mintAmount + ' ' + (this.state.mintAmount > 1 ? "AVAXGHOSTS" : "AVAXGHOST")) : "COMING SOON"}</span>
                        : <span>CONNECT</span>
                      }
                    </a>
                  </div>
                )
                :
                (
                  <div className="loading">
                    <div></div>
                    <div></div>
                  </div>
                )
                }
              </div>
              <div className="col-xl-3 col-lg-6"></div>
            </div>
          </div>
        </div>
      </section>
    )
  }

}