import React from "react";
import Layout from "../components/Layout";
import Nav from "../components/Nav";
import Banner from "../components/Banner";
import Museum from "../components/Museum";
import Versus from "../components/Versus";
import Roadmap from "../components/Roadmap";
import Team from "../components/Team";
import Partnerships from "../components/Partnerships";
import Faq from "../components/Faq";
import Footer from "../components/Footer";

const Home = () => (
  <Layout pageTitle="AVAXGHOST NFT Game">
    <Nav />
    <Banner />
    <Museum />
    <Versus />
    <Roadmap />
    <Team />
    <Partnerships />
    <Faq />
    <Footer />
  </Layout>
);

export default Home;