import React from "react";
import ghost1 from "../assets/images/team/g1.jpeg"
import ghost2 from "../assets/images/team/g2.jpeg"
import ghost3 from "../assets/images/team/g3.jpeg"
import ghost4 from "../assets/images/team/g4.jpeg"
import ghost5 from "../assets/images/team/g5.jpeg"
import ghost6 from "../assets/images/team/g6.jpeg"
import ghost7 from "../assets/images/team/g7.jpeg"
import ghost8 from "../assets/images/team/g8.jpeg"
import ghost9 from "../assets/images/team/g9.jpeg"

const Team = () => {
  return (
    <section className="service-one" id="team">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            <span>TEAM</span> <br />
          </h2>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInDown" data-wow-duration="1500ms">
            <div className="service-one__single text-center">
            <div className="service-one__inner">
                <img src={ghost1} alt="cto"></img>
                <a href="https://github.com/erencelik" target="_blank" rel="noreferrer">
                  <h2>
                    H. Eren ÇELİK
                  </h2>
                </a>
                <h3>
                  Nerd Ghost
                </h3>
                <h5>
                  Blockchain Developer
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms">
            <div className="service-one__single text-center">
            <div className="service-one__inner">
                <img src={ghost2} alt="cvo"></img>
                <a href="https://twitter.com/baraneryilmaz" target="_blank" rel="noreferrer">
                  <h2>
                    Baran ERYILMAZ
                  </h2>
                </a>
                <h3>
                  Obsessive Ghost
                </h3>
                <h5>
                  Art Director
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms">
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <img src={ghost3} alt="ceo"></img>
                <a href="https://twitter.com/cenkunlutr" target="_blank" rel="noreferrer">
                  <h2>
                    Cenk ÜNLÜ
                  </h2>
                </a>
                <h3>
                  Social Ghost
                </h3>
                <h5>
                  Executive Operations
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms">
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <img src={ghost4} alt="cco"></img>
                <a href="https://twitter.com/turkerbalkar" target="_blank" rel="noreferrer">
                  <h2>
                    Türker BALKAR
                  </h2>
                </a>
                <h3>
                  Wise Ghost
                </h3>
                <h5>
                  Advisor
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms">
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <img src={ghost5} alt="cdo"></img>
                <a href="https://twitter.com/YucelKaraman7" target="_blank" rel="noreferrer">
                  <h2>
                    Yücel KARAMAN
                  </h2>
                </a>
                <h3>
                  Gamer Ghost
                </h3>
                <h5>
                  Unity Developer
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms">
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <img src={ghost6} alt="cdo"></img>
                <a href="https://twitter.com/ozgup" target="_blank" rel="noreferrer">
                  <h2>
                    Özgü PUHALOĞLU
                  </h2>
                </a>
                <h3>
                  Warlock Ghost
                </h3>
                <h5>
                  Community Manager
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms">
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <img src={ghost7} alt="cco"></img>
                <a href="https://linkedin.com/in/amarachi-emmanuel-9b9406b2" target="_blank" rel="noreferrer">
                  <h2>
                    Amarachi EMMANUEL
                  </h2>
                </a>
                <h3>
                  Expressive Ghost
                </h3>
                <h5>
                  Community Manager
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms">
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <img src={ghost8} alt="cco"></img>
                <a href="https://twitter.com/pulpcoke" target="_blank" rel="noreferrer">
                  <h2>
                    Uğurcan GÖKSU
                  </h2>
                </a>
                <h3>
                  Pulp Ghost
                </h3>
                <h5>
                  Jr. Community Manager
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-sm-12 text-center mt-5 wow fadeInUp" data-wow-duration="1500ms">
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <img src={ghost9} alt="cco"></img>
                <a href="#!">
                  <h2>
                    A-ghost-o "Agusto"
                  </h2>
                </a>
                <h3>
                  Pawed Ghost
                </h3>
                <h5>
                  MEOW!
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
