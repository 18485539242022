import React from 'react';
import opensea from "../assets/images/resources/opensea.png";
import kalao from "../assets/images/resources/kalao.png";
import yeti from "../assets/images/resources/yeti-swap.png";
const Partnerships = () => {
    return (
        <section className="partnerships" id="partners">
            <div className="container">
                <div className="block-title text-center">
                    <h2 className="block-title__title">
                        <span>MARKETPLACES</span> <br />
                    </h2>
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <div className="partnership-brand">
                            <a href="https://opensea.io/collection/avaxghost-nft" target="_blank" rel="noreferrer">
                                <img className="footer-widget__image" src={opensea} alt="opensea" height="180vh" style={{padding:"24px"}}></img>
                                <br />
                                <span>OpenSea</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="partnership-brand">
                            <a href="https://marketplace.kalao.io/collection/0xa64856f831d4793e781a4912a2389052348f9459" target="_blank" rel="noreferrer">
                                <img className="footer-widget__image" src={kalao} alt="kalao" height="180vh" style={{padding:"24px"}}></img>
                                <br />
                                <span>Kalao</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="partnership-brand">
                            <a href="https://exchange.yetiswap.app/#/collection/0xa64856F831D4793E781A4912A2389052348f9459" target="_blank" rel="noreferrer">
                                <img className="footer-widget__image" src={yeti} alt="yeti-swap" height="180vh" style={{padding:"24px"}}></img>
                                <br />
                                <span>Yeti Swap</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Partnerships;