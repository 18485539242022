import React, {Component} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class Museum extends Component {

    render() {
        const slidesToShow = window.innerWidth > 800 ? 12 : 4;
        const sizeVw = 96 / slidesToShow;
        const size = sizeVw + "vw";
        const settings = {
            autoplay: true,
            autoplaySpeed: 0,
            speed: 3600,
            dots: false,
            infinite: true,
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
            pauseOnHover: false,
            pauseOnDotsHover: false,
            pauseOnFocus: false,
            arrows: false,
            cssEase: "linear",
            waitForAnimate: true
        };
        const settingsRtl = {...settings, rtl: true}
        let images = [];
        let images1 = [];
        let images2 = [];
        let images3 = [];
        let i = 1;
        for (; i <= 48; i++) {
            images.push(<div className="museum-item" key={"g-" + i}><div style={{width: size, height: size, backgroundImage: "url(" + require("../assets/images/museum/" + i + ".png") + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}></div></div>);
        }
        images = images.sort(() => Math.random() - 0.5);
        images1 = images.slice(0, 16);
        images2 = images.slice(15, 31);
        images3 = images.slice(31, 47);
        return (
            <section className="museum" id="museum">
              <div className="carousel-container">
                <Slider {...settings}>
                    {images1}
                </Slider>
                <Slider {...settingsRtl}>
                    {images2}
                </Slider>
                <Slider {...settings}>
                    {images3}
                </Slider>
              </div>
            </section>
        );
    }

}
