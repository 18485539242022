import React, { Component } from "react";
import avalanche from "../assets/images/resources/avalanche.png";

export default class Blog extends Component {
  
  constructor() {
    super();
    this.state = {
      scrollBtn: false
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollTop = this.scrollTop.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (window.scrollY > 70) {
      this.setState({
        scrollBtn: true
      });
    } else if (window.scrollY < 70) {
      this.setState({
        scrollBtn: false
      });
    }
  }

  scrollTop = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div>
        <footer className="site-footer">
          <div className="site-footer__upper">
            <div className="container">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8">
                  <div className="footer-widget">
                    <div className="site-footer__social">
                      <a href="https://twitter.com/avaxghost" aria-label="social icon" aria-hidden="true" target="_blank" rel="noreferrer">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="https://discord.gg/avaxghost" aria-label="social icon" aria-hidden="true" target="_blank" rel="noreferrer">
                        <i className="fab fa-discord"></i>
                      </a>
                      <a href="https://t.me/AvaxGhostGame" aria-label="social icon" aria-hidden="true" target="_blank" rel="noreferrer">
                        <i className="fab fa-telegram"></i>
                      </a>
                      <a href="https://medium.com/@AvaxGhostGame" aria-label="social icon" aria-hidden="true" target="_blank" rel="noreferrer">
                        <i className="fab fa-medium"></i>
                      </a>
                      <a href="https://www.youtube.com/c/AvaxGhostGame" aria-label="social icon" aria-hidden="true" target="_blank" rel="noreferrer">
                        <i className="fab fa-youtube"></i>
                      </a>
                      <a href="https://docs.avaxghost.com" aria-label="social icon" aria-hidden="true" target="_blank" rel="noreferrer" className="btn-gitbook">
                        <i className="fa fa-book"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2"></div>
              </div>
              <div className="row">
                <div className="col-lg-3"></div>
                <div className="col-lg-6">
                  <div className="footer-widget">
                    <img className="footer-widget__image" src={avalanche} alt="avalanche" height="180vh"></img>
                  </div>
                </div>
                <div className="col-lg-3"></div>
              </div>
            </div>
          </div>
          <div className="site-footer__bottom">
            <div className="container">
              <div className="inner-container text-center">
                <p className="site-footer__copy">
                  &copy; copyright {new Date().getFullYear()}
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <a href="#">avaxghost</a>
                </p>
              </div>
            </div>
          </div>
        </footer>
        <div onClick={this.scrollTop} onKeyDown={this.scrollTop} role="button" tabIndex="0" className="scroll-to-target scroll-to-top" style={{ display: this.state.scrollBtn ? "block" : "none" }}>
          <i className="fa fa-angle-up"></i>
        </div>
      </div>
    );
  }
}
