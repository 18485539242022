import React, { Component } from 'react';
import { randomNumber } from '../assets/scripts/globals.js';
import "../assets/css/particles.css";
import vsLogo from "../assets/images/resources/vs-logo.png";
import ghostPurple from "../assets/images/resources/ghost-purple.png";
import ghostGreen from "../assets/images/resources/ghost-green.png";
import { queryTeamScore } from '../assets/scripts/contract.js';
import { log } from '../assets/scripts/logger.js';

export default class Versus extends Component {
    
    constructor() {
        super()
        this.state = {
            intervalId: undefined,
            progress: 50
        }
    }

    componentDidMount() {
        const intervalId = setInterval(() => this.updateProgress(), 4000);
        this.setState({ intervalId })
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    updateProgress() {
        queryTeamScore().then((score) => {
            if(score) {
                // log(score);
                const total = score.purple + score.green;
                const purple = score.purple * 100 / total;
                const green = score.green * 100 / total;
                log(`[VERSUS]: total: ${total}, purple: ${purple}, green: ${green}`);
                this.setState({progress: purple});
            } else {
                this.setState({ progress: randomNumber(42, 58) });
            }
        }).catch(() => {
            this.setState({ progress: randomNumber(42, 58) });
        });
    }

    render() {
        var particles = [];
        for (var i = 0; i < 100; i++) {
            particles.push(<div className="circle-container" key={"cg-" + i}><div className="circle green"></div></div>);
            particles.push(<div className="circle-container" key={"cp-" + i}><div className="circle purple"></div></div>);
        }
        return (
            <section className="versus" id="versus">
              <div className="container">
                <div className="vs-logo">
                    <img src={vsLogo} alt="" />
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="versus-team-purple">
                            <img src={ghostPurple} alt="" height="200" />
                            <br /><br />
                            <span>PURPLE</span>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                        <div className="progressbar-container">
                            <div className="progressbar-complete" style={{width: (this.state.progress + "%")}}>
                                <div className="progressbar-liquid"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="versus-team-green">
                            <img src={ghostGreen} alt="" height="200" />
                            <br /><br />
                            <span>GREEN</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-sm-12 text-center mt-5">
                        <iframe className="youtube-iframe" src="https://www.youtube.com/embed/nOx2Hq9FyS4" title="AVAXGHOST Trailer" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                        </iframe>
                    </div>
                </div>
                <div className="particles-container">
                    {particles}
                </div>
              </div>
            </section>
        );
    }

}
